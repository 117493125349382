<template>
  <div class="carousel"> 
    <div class="slides" ref="slidesContainer">
      <div 
        v-for="(slideData, index) in slides" 
        :key="index" 
        class="slide">
        <slot name="slide" :data="slideData" :index="index"></slot>
      </div>
    </div>
    <div class="dots-wrapper" v-if="slides.length > 1" :data-fit="slides.length <= maxDots ? 1 : 0">
      <div class="dots" :style="dotsStyle">
        <span 
          v-for="(slide, index) in slides" 
          :key="index" 
          :class="{'active': index === activeSlide}">
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

interface PageProps {
  slides: Array<any>    
}

const props = withDefaults(defineProps<PageProps>(), {
  slides: []  
})

const emits = defineEmits(['slideEnd']);


const slidesContainer = ref(null);
const activeSlide = ref(0);
const maxDots = 5;

onMounted(() => {
  slidesContainer.value.addEventListener('scroll', onScroll, { passive: true });
});

function onScroll() {
  const container = slidesContainer.value;
  const slideWidth = container.offsetWidth;
  const scrollLeft = container.scrollLeft;

  const newActiveSlide = Math.round(scrollLeft / slideWidth);
  if (newActiveSlide !== activeSlide.value) {
    activeSlide.value = newActiveSlide;
    emits('slideEnd', newActiveSlide);
  }
}

const dotsStyle = computed(() => {
  const totalSlides = props.slides.length;
  const dotWidth = 16; 
  const maxVisibleDots = Math.min(totalSlides, maxDots);
  const wrapperWidth = maxVisibleDots * dotWidth;
  const totalDotsWidth = totalSlides * dotWidth;

  let offset = 0;
  if (totalSlides > maxDots) {
    const middleIndex = Math.floor(maxDots / 2);
    const maxOffset = (totalSlides - maxDots) * dotWidth;
    offset = (activeSlide.value - middleIndex) * dotWidth;

    if (offset < 0) offset = 0;
    if (offset > maxOffset) offset = maxOffset;
  }

  return {
    transform: `translateX(-${offset}px)`,
    transition: 'transform 0.3s ease',
    width: `${totalDotsWidth}px`,
  };
});
</script>

<style scoped>
.carousel {
  position: relative;
}

.carousel .slides { 
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  display: flex;
}
.carousel .slides::-webkit-scrollbar {
  display: none; 
}
.carousel .slide {
  scroll-snap-stop: always;
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 100%;
}

.carousel .dots-wrapper {
  overflow: hidden;
  width: 80px; /* maxDots * dotWidth (5 * 16px) */
  margin: 0 auto;
  position: relative;
  margin-top: 10px; 
  /* margin-bottom: 25px; */
}

.carousel .dots-wrapper[data-fit="1"] {
  display: flex;
  justify-content: center;
}

.carousel .dots {
  display: flex;
  transition: transform 0.3s ease;
  width: max-content;
}

.carousel .dots span {
  width: 8px;
  height: 8px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 4px;
  /* border: 1px solid #0000005b; */
  flex-shrink: 0;
}

.carousel .dots .active {
  background-color: var(--un-firm);
  /* border: 1px solid #cccccc6f; */
}

.carousel :deep(.slide){
  scroll-snap-stop: always;
  scroll-snap-align: center;
  contain: layout;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  position: relative;
}
</style>