<template>

  <div :class="{'chart': true, 'selected': props.activeId}">
    <svg :width="props.sizePx" :height="props.sizePx" :viewBox="'0 0 ' + viewBoxSize + ' ' + viewBoxSize" class="donut">
      <circle
        class="donut-ring"
        :cx="viewBoxSize / 2"
        :cy="viewBoxSize / 2"
        :r="radius"
        fill="transparent"
        :stroke-width="props.strokeWidth"
        stroke="#d2d3d4"
      ></circle>
      <circle
        v-for="segment in chartData"
        :key="segment.id"
        :nut-id="segment.id"
        :class="{'donut-segment': true, 'active': segment.source.id == props.activeId}"
        :cx="viewBoxSize / 2"
        :cy="viewBoxSize / 2"
        :r="radius"
        fill="transparent"
        :stroke="segment.source.color ? segment.source.color : getColorByNumber(segment.id)"
        :stroke-width="props.strokeWidth"
        :stroke-dasharray="segment.dashArray"
        :stroke-dashoffset="segment.dashOffset"
      ></circle>
    </svg>

    <div class="caption">
      <slot></slot>
    </div>

  </div>
</template>

<script setup lang="ts">

import { getColorByNumber } from '@/types/other';
 

interface PageProps {
  data: Array<any>,
  activeId?: number,
  size?: number,
  sizePx?: string,
  strokeWidth?: number
}

const props = withDefaults(defineProps<PageProps>(), {
  data: [],
  activeId: 0,
  size: 100,
  sizePx: '200px',
  strokeWidth: 4
})

const emits = defineEmits(['sectionClick']);


const viewBoxSize = computed(() => props.size);

const radius = computed(() => viewBoxSize.value / 2 - props.strokeWidth / 2);

const circumference = computed(() => 2 * Math.PI * radius.value);

const chartData = computed(() => {
  let totalValue = props.data.reduce((sum, item) => sum + item.value, 0);
  let runningTotal = 0;
  return props.data.map(item => {
    let valuePercentage = item.value / totalValue;
    let dashArray = circumference.value * valuePercentage;
    let dashOffset = -runningTotal / totalValue * circumference.value;
    runningTotal += item.value;

    return {
      id: item.id,
      source: item,
      dashArray: dashArray + ' ' + (circumference.value - dashArray),
      dashOffset
    };
  });
});


</script>


<style scoped>
.donut {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.donut-ring {
  stroke: #d2d3d4;
}

.donut-segment {
  transition: stroke-dashoffset 0.3s;
  pointer-events:painted; 
  position: relative;
}

.selected .donut-segment:not(.active){
  /* opacity: 0.03; */
}


/* .donut-segment:nth-child(3) {
  z-index: 1000;
  filter: blur(10px);
  pointer-events: visibleStroke;
} */
/* .donut-segment:hover {
  filter: blur(10px);
  z-index: 1;
} */
.chart{
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  aspect-ratio: 1/1;
  display: flex;
}
.caption{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  pointer-events: none;
}
</style>
